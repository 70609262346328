export const START_GET_TRACKERS = "START_GET_TRACKERS";
export const SUCCESS_GET_TRACKERS = "SUCCESS_GET_TRACKERS";
export const ERROR_GET_TRACKERS = "ERROR_GET_TRACKERS";

export const START_CREATE_TRACKER = "START_CREATE_TRACKER";
export const SUCCES_CREATE_TRACKER = "SUCCES_CREATE_TRACKER";
export const ERROR_CREATE_TRACKER = "ERROR_CREATE_TRACKER";

export const START_DELETE_TRACKER = "START_DELETE_TRACKER";
export const SUCCES_DELETE_TRACKER = "SUCCES_DELETE_TRACKER";
export const ERROR_DELETE_TRACKER = "ERROR_DELETE_TRACKER";