export const START_USER_LOGIN = "START_USER_LOGIN";
export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const ERROR_USER_LOGIN = "ERROR_USER_LOGIN";

export const START_USER_REGISTRATION = "START_USER_REGISTRATION";
export const SUCCESS_USER_REGISTRATION = "SUCCESS_USER_REGISTRATION";
export const ERROR_USER_REGISTRATION = "ERROR_USER_REGISTRATION";

export const START_GET_USER_DETAILS = "START_GET_USER_DETAILS";
export const SUCCESS_GET_USER_DETAILS = "SUCCESS_GET_USER_DETAILS";
export const ERROR_GET_USER_DETAILS = "ERROR_GET_USER_DETAILS";

export const START_EDIT_USER_DETAILS = "START_EDIT_USER_DETAILS";
export const SUCCESS_EDIT_USER_DETAILS = "SUCCESS_EDIT_USER_DETAILS";
export const ERROR_EDIT_USER_DETAILS = "ERROR_EDIT_USER_DETAILS";

export const START_CREATE_PAYMENT_LINK = "START_CREATE_PAYMENT_LINK";
export const SUCCESS_CREATE_PAYMENT_LINK = "SUCCESS_CREATE_PAYMENT_LINK";
export const ERROR_CREATE_PAYMENT_LINK = "ERROR_CREATE_PAYMENT_LINK";

export const START_FORGOT_PASSWORD_REQUEST = "START_FORGOT_PASSWORD_REQUEST";
export const SUCCESS_FORGOT_PASSWORD_REQUEST = "SUCCESS_FORGOT_PASSWORD_REQUEST";
export const ERROR_FORGOT_PASSWORD_REQUEST = "ERROR_FORGOT_PASSWORD_REQUEST";

export const UPDATE_USER_CREDITS = "UPDATE_USER_CREDITS"; 
export const UPDATE_SUBSCRIPTION_DETAILS = "UPDATE_SUBSCRIPTION_DETAILS"; 