import API from "./api";

export function login(data) {
    return API.post(`auth/login`, data);
}

export function register(data) {
    return API.post(`auth/register`, data);
}

export function userDetails() {
    return API.get(`user`);
}

export function editUserDetails(id,data) {
    return API.put(`user/${id}` , data);
}

export function createPaymentLink(data) {
    return API.post(`subscribe/create-subscription-link` , data);
}

export function submitFeedBack(data) {
    return API.post(`user/feedback` , data);
}

export function forgotPassword(data) {
    return API.post(`auth/forgot-password` , data);
}

export function validateResetToken(data) {
    return API.post(`auth/validate-reset-token` , data);
}

export function verifyEmail(data) {
    return API.post(`auth/verify-email` , data);
}

export function resendVerificationLink() {
    return API.post(`auth/resend-verification-link`);
}

export function ResetPassword(data) {
    return API.post(`auth/reset-password` , data);
}


export function getAdminUsersList(params) {
    return API.get(`user/admin`,params);
}


export function createSubscriptionUpdateRequest(data) {
    return API.post(`subscribe/create-subscription-update` , data);
}

export function checkPassword(data) {
    return API.post(`auth/check-password` , data);
}

export function changePassword(data) {
    return API.post(`auth/change-password` , data);
}
export function updateSubscription (data) {
    return API.post(`subscribe/update` , data);
}

