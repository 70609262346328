
import {
    START_GET_TRACKERS,
    SUCCESS_GET_TRACKERS,
    ERROR_GET_TRACKERS,
    START_CREATE_TRACKER,
    SUCCES_CREATE_TRACKER,
    ERROR_CREATE_TRACKER,
    START_DELETE_TRACKER,
    SUCCES_DELETE_TRACKER,
    ERROR_DELETE_TRACKER,
} from "../actions/trackerAction";

const initialState = {
    trackersLoading :false,
    trackersList : []
}


const userReducer = (prevState, action) => {
    switch (action.type) {
        case START_GET_TRACKERS:
            return {
                    ...prevState,
                    trackersLoading :true,
            };
        case SUCCESS_GET_TRACKERS:
            return {
                ...prevState,
                trackersLoading :false,
                trackersList : action.data
        };
        case ERROR_GET_TRACKERS:
            return {
                ...prevState,
                trackersLoading :false,
        };
        case START_DELETE_TRACKER:
            return {
                    ...prevState,
                    trackersLoading :true,
            };
        case SUCCES_DELETE_TRACKER:
            return {
                ...prevState,
                trackersLoading :false,
                trackersList : prevState?.trackersList?.filter(a => a._id !== action.id)
        };
        case ERROR_DELETE_TRACKER:
            return {
                ...prevState,
                trackersLoading :false,
        };
        default:
            return prevState || initialState;
    }
};

export default userReducer;
