import API from "./api";

export function getAdminMessages(params) {
  return API.post(`chat/admin`, params);
}

export function getAdminUsers(params) {
  return API.post(`user/admin/list`, params);
}

export function updateUserAdmin(id, data) {
  return API.post(`user/admin/update/${id}`, data);
}

export function getAdminSubscriptions(params) {
  return API.post(`subscribe/admin/list`, params);
}

export function getUsersSubscription(id) {
  return API.get(`subscribe/admin/${id}`);
}

export function updateUserSubscription(id, params) {
  return API.post(`subscribe/admin/update/${id}`, params);
}

export function getDashboardStatsMessage(params) {
  return API.post(`chat/admin/msgchart`, params);
}

export function getRealEstateCount() {
  return API.get(`import/real-estate/count`);
}
