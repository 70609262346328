import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { START_GET_USER_DETAILS } from "../actions/userAction";
import { useEffect } from "react";

let called = 0;
let DontRedirect = [
  "chat",
  "account",
  "saved-results",
  "manage-subscription",
  "plans",
  "admin",
  "admin-user",
  // "admin-import",
  "admin-subscription",
  "trackers",
];

const PrivateRoutes = () => {
  let token = window.localStorage.getItem("token");
  let pathname = window.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!called) {
      dispatch({
        type: START_GET_USER_DETAILS,
        onSuccess: (res) => {
          if (pathname == "admin") {
            if (!res.data.isAdmin) {
              navigate("/");
            }
          }
          if (res.data.email_verified == true) {
            if (!DontRedirect.includes(pathname)) {
              navigate("/chat");
            }
          } else {
            navigate("/pending-verification");
          }
        },
        onError: () => {
          window.localStorage.clear();
          window.location.reload();
        },
      });
      called = 1;
    }
  }, []);

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
