import { createTheme } from "@mui/material/styles";
import {
  MuiButton,
  MuiTypography,
  MuiTextField,
  MuiDivider,
  MuiFormHelperText,
  MuiAvatar,
  MuiMenu,
  MuiSkeleton,
  MuiTooltip,
  MuiAutocomplete,
  MuiSelect,
  MuiBadge,
} from "./Themecomponents";

const theme = createTheme({
  color: {
    primary: {
      main: "#06BAD4",
      light: "#58B9FF"
    },
    secondary: {
      main: "#D1ECFF",
      hover: "#c3dbec",
    },
    accent: {
      main: "#EB5054"
    },
    background: {
      main: "#1C1C26",
      secondary: "#3A3A51",
      paper: "#FFFFFF",
      default: "#F7FAFC",
      grey: "#F7F7F8",
      red: "#E9514E",
      gradient: "linear-gradient(346.94deg, #02BAE7 -14.2%, #07BBD0 36.76%, #1BBE6C 107.63%)"
    },
    black: {
      main: "#383838",
    },
    white: {
      main: "#ffffff",
    },
    red: {
      main: "#EF5054"
    },
    greyLight: {
      main: "#565656",
    },
    grey: {
      main: "#464646",
      mid: "#939393",
      dark: "#555555",
      light: "#F6F6F6"
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiButton: {
      ...MuiButton,
    },
    MuiTypography: {
      ...MuiTypography,
    },
    MuiTextField: {
      ...MuiTextField,
    },
    MuiDivider: {
      ...MuiDivider,
    },
    MuiFormHelperText: {
      ...MuiFormHelperText
    },
    MuiAvatar: {
      ...MuiAvatar,
    },
    MuiMenu: {
      ...MuiMenu
    },
    MuiSkeleton : {
      ...MuiSkeleton,
    },
    MuiTooltip : {
      ...MuiTooltip
    },
    MuiAutocomplete : {
      ...MuiAutocomplete
    },
    MuiSelect : {
      ...MuiSelect
    },
    MuiBadge : {
      ...MuiBadge,
    },
  },
  breakpoints: {
      xs: 375,
      ssm : 541,
      sm: 769,
      md: 931,
      lg: 1200,
      xl: 1536,
    },
});

export default theme;
