
import {
    START_GET_USER_DETAILS,
    SUCCESS_GET_USER_DETAILS,
    ERROR_GET_USER_DETAILS,
    SUCCESS_EDIT_USER_DETAILS,
    UPDATE_USER_CREDITS,
    UPDATE_SUBSCRIPTION_DETAILS,
} from "../actions/userAction";

const initialState = {
    userDetailsLoading :false,
    userDetails : null
}


const userReducer = (prevState, action) => {
    switch (action.type) {
        case START_GET_USER_DETAILS:
            return {
                    ...prevState,
                    userDetailsLoading :true,
            };
        case SUCCESS_GET_USER_DETAILS:
            return {
                ...prevState,
                userDetailsLoading :false,
                userDetails : action.data
        };
        case ERROR_GET_USER_DETAILS:
            return {
                ...prevState,
                userDetailsLoading :false,
        };
        case SUCCESS_EDIT_USER_DETAILS:
            return {
                ...prevState,
                userDetailsLoading :false,
                userDetails : action.data
        };
        case UPDATE_USER_CREDITS:
                return {
                    ...prevState,
                    userDetails : {
                        ...prevState.userDetails,
                            credits : action.data,
                    }
                }   
        case UPDATE_SUBSCRIPTION_DETAILS:
            return {
                ...prevState,
                userDetails : {
                    ...prevState.userDetails,
                    subscriptionDetails :action.data
                }
            }
        default:
            return prevState || initialState;
    }
};

export default userReducer;
