import {
  START_GET_USER_LIST,
  SUCCESS_GET_USER_LIST,
  ERROR_GET_USER_LIST,
  START_UPDATE_USER_DETAILS,
  SUCCESS_UPDATE_USER_DETAILS,
  ERROR_UPDATE_USER_DETAILS,
} from "../actions/adminAction";

const initialState = {
  userListLoading: true,
  userList: [],
  currentPage: 1,
  totalPages: null,
  totalUsers: 0,
  userDetailsUpdated: false,
};

const adminReducer = (prevState, action) => {
  switch (action.type) {
    case START_GET_USER_LIST:
      return {
        ...prevState,
        userListLoading: true,
      };
    case SUCCESS_GET_USER_LIST:
      console.log("reducer------", action.data);
      return {
        ...prevState,
        userListLoading: false,
        userList:
          action.params.page == 1
            ? action.data.data
            : [...prevState.userList, ...action.data.data],
        currentPage: action.data.currentPage,
        totalPages: action.data.totalPages,
        totalUsers: action.data.totalUsers,
      };
    case ERROR_GET_USER_LIST:
      return {
        ...prevState,
        userListLoading: false,
      };
    case START_UPDATE_USER_DETAILS:
      return {
        ...prevState,
        userDetailsUpdated: false,
      };
    case SUCCESS_UPDATE_USER_DETAILS:
      let updatedList = prevState.userList.map((user) => {
        if (user._id == action.userId) {
          return action.data;
        } else return user;
      });

      return {
        ...prevState,
        userList: updatedList,
        userDetailsUpdated : true
      };
    case ERROR_UPDATE_USER_DETAILS:
      return {
        ...prevState,
        userDetailsUpdated: false,
      };

    default:
      return prevState || initialState;
  }
};

export default adminReducer;
