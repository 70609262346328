import {
  call,
  put,
  takeEvery,
} from "@redux-saga/core/effects";


import {
  START_GET_USER_LIST,
  SUCCESS_GET_USER_LIST,
  ERROR_GET_USER_LIST,
  START_UPDATE_USER_DETAILS,
  SUCCESS_UPDATE_USER_DETAILS,
  ERROR_UPDATE_USER_DETAILS,
} from "../actions/adminAction";

import { getAdminUsers, updateUserAdmin } from "../apis/admin";


function* adminUserListWorker({params, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(getAdminUsers , params);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_USER_LIST,
        data : response.data,
        params,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_GET_USER_LIST,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_USER_LIST,
    });
    onError(response)
  }
}

export function* adminUserListWatcher() {
  yield takeEvery(START_GET_USER_LIST, adminUserListWorker);
}




function* adminUpdateUserWorker({userId,data, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(updateUserAdmin , userId,data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_USER_DETAILS,
        data : response.data,
        userId,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_UPDATE_USER_DETAILS,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_UPDATE_USER_DETAILS,
    });
    onError(response)
  }
}

export function* adminUpdateUserWatcher() {
  yield takeEvery(START_UPDATE_USER_DETAILS, adminUpdateUserWorker);
}

