import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL || "http://localhost:5000/";

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 1000 * 60 * 30,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  validateStatus(status) {
    return (
      (status >= 200 && status < 300) ||
      status === 401 ||
      status === 422 ||
      status === 400
    ); // default
  },
});

async function get(url, paramObj = {}, headers = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
  const res = await instance
    .get(url, { params: paramObj })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      if (response.status === 201) {
        return response.data;
      }
      if (response.status === 401) {
        return {
          status: false,
          response_status: response.status,
          unauthenticated: true,
          redirect_to_login: true,
        };
      }
      return {
        success: false,
        message: response.message || "Ill formed response",
        redirect_to_login: true,
      };
    })
    .then((response) => {
      if (response.success) {
        return response;
      }
      return response;
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
        error: error
      };
    });

  return res;
}

async function post(url, paramObj, signal, headers = {}) {
  const abortController = new AbortController();

  const combinedSignal = signal ? signal : abortController.signal;
  instance.defaults.signal = combinedSignal;
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;

  try {
    const res = await instance.post(url, paramObj);
    if (res.data.success) {
      return res.data;
    }

    if (res.data.status === 401) {
      return {
        status: false,
        response_status: res.data.status,
        unauthenticated: true,
        redirect_to_login: true,
      };
    }

    return res.data;
  } catch (error) {
    if (combinedSignal.aborted) {
      return {
        status: false,
        message: "Request aborted",
        aborted: true,
      };
    }
    return {
      status: false,
      message: error.message || "Something went wrong! Try again later",
      response: error.response?.data,
    };
  } finally {
    instance.defaults.signal = undefined;
  }
}


async function put(url, paramObj, headers = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
  const res = await instance
    .put(url, paramObj)
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      if (response.success) {
        return response;
      }

      if (response.status === 401) {
        return {
          status: false,
          response_status: response.status,
          unauthenticated: true,
          redirect_to_login: true,
        };
      }

      return response;
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || "Something went to wrong! Try again later",
      };
    });

  return res;
}

async function deleteM(url, payload = {}, headers = {}) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
  const res = await instance
    .delete(url, { data: payload })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      if (response.status === 401) {
        return { status: false, unauthenticated: true };
      }
      if (response.status === 201) {
        return response.data;
      }
      return {
        success: false,
        message: response.message || "Ill formed response",
      };
    })
    .then((response) => {
      if (response.success) {
        return response;
      }
      return response;
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });

  return res;
}

export default {
  get,
  post,
  put,
  deleteM,
};
