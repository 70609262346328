import { Navigate, Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { START_GET_USER_DETAILS } from '../actions/userAction'
import { useEffect } from 'react'

const AuthRoutes = () => {
    let token = window.localStorage.getItem("token")
    const dispatch = useDispatch()
    
    useEffect(() => {
        if(token){
        dispatch({
            type : START_GET_USER_DETAILS,
            onError : () => {
                window.localStorage.clear()
                window.location.reload()
            }
        })
    }
    },[])

    
    return (
        token ? <Navigate to='/chat' /> : <Outlet />
    )
}

export default AuthRoutes