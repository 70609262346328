import {
  call,
  put,
  takeEvery,
} from "@redux-saga/core/effects";

import {
  START_USER_LOGIN,
  SUCCESS_USER_LOGIN,
  ERROR_USER_LOGIN,
  START_USER_REGISTRATION,
  SUCCESS_USER_REGISTRATION,
  ERROR_USER_REGISTRATION,
  START_GET_USER_DETAILS,
  SUCCESS_GET_USER_DETAILS,
  ERROR_GET_USER_DETAILS,
  START_EDIT_USER_DETAILS,
  SUCCESS_EDIT_USER_DETAILS,
  ERROR_EDIT_USER_DETAILS,
  START_CREATE_PAYMENT_LINK,
  SUCCESS_CREATE_PAYMENT_LINK,
  ERROR_CREATE_PAYMENT_LINK,
  START_FORGOT_PASSWORD_REQUEST,
  SUCCESS_FORGOT_PASSWORD_REQUEST,
  ERROR_FORGOT_PASSWORD_REQUEST,
} from "../actions/userAction";

import {
  editUserDetails,
  login,
  register,
  userDetails,
  createPaymentLink,
  forgotPassword,
} from "../apis/userApi";


function* loginUserWorker({ data, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(login, data);

  try {
    if (response.status) {
      window.localStorage.setItem("token", response.accessToken)
      yield put({
        type: SUCCESS_USER_LOGIN,
      });
      onSuccess(response)
      if(response.email_verified == true){
        window.location.href = `${window.location.origin}`
    } else {
      window.location.href = `${window.location.origin}/pending-verification`
    }

    } else {
      yield put({
        type: ERROR_USER_LOGIN,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_USER_LOGIN,
    });
    onError(response)
  }
}

export function* loginUserWatcher() {
  yield takeEvery(START_USER_LOGIN, loginUserWorker);
}


function* registerUserWorker({ data, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(register, data);

  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_REGISTRATION,
      });
      onSuccess(response)
      window.localStorage.setItem("token", response.accessToken)
      window.location.href = `${window.location.origin}/pending-verification`
    } else {
      yield put({
        type: ERROR_USER_REGISTRATION,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_USER_REGISTRATION,
    });
    onError(response)
  }
}

export function* registerUserWatcher() {
  yield takeEvery(START_USER_REGISTRATION, registerUserWorker);
}

function* userDetailsWorker({onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(userDetails);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_USER_DETAILS,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_GET_USER_DETAILS,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_USER_DETAILS,
    });
    onError(response)
  }
}

export function* userDetailsWatcher() {
  yield takeEvery(START_GET_USER_DETAILS, userDetailsWorker);
}

function* editUserDetailsWorker({id, data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(editUserDetails , id ,data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_EDIT_USER_DETAILS,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_EDIT_USER_DETAILS,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_EDIT_USER_DETAILS,
    });
    onError(response)
  }
}

export function* editUserDetailsWatcher() {
  yield takeEvery(START_EDIT_USER_DETAILS, editUserDetailsWorker);
}


function* createPaymentLinkWorker({data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(createPaymentLink ,data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_CREATE_PAYMENT_LINK,
        data : response.data
      });
      window.location.href=response.url
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_CREATE_PAYMENT_LINK,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_CREATE_PAYMENT_LINK,
    });
    onError(response)
  }
}

export function* createPaymentLinkWatcher() {
  yield takeEvery(START_CREATE_PAYMENT_LINK, createPaymentLinkWorker);
}


function* forgotPasswordWorker({data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(forgotPassword ,data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_FORGOT_PASSWORD_REQUEST,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_FORGOT_PASSWORD_REQUEST,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_FORGOT_PASSWORD_REQUEST,
    });
    onError(response)
  }
}

export function* forgotPasswordWatcher() {
  yield takeEvery( START_FORGOT_PASSWORD_REQUEST  , forgotPasswordWorker);
}