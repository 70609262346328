import API from "./api";

export function getTrackersList() {
    return API.get(`tracker`);
}

export function createTracker(data) {
    return API.post(`tracker` , data);
}

export function deleteTracker(id) {
    return API.deleteM(`tracker/${id}`);
}