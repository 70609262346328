import {
  call,
  put,
  takeEvery,
  select,
  fork,
  cancel,
  take,
  race,
} from "@redux-saga/core/effects";

import {
  START_SEND_MESSAGE,
  SUCCESS_SEND_MESSAGE,
  ERROR_SEND_MESSAGE,
  CANCEL_SEND_MESSAGE,
  START_GET_CHAT_HISTORY,
  SUCCESS_GET_CHAT_HISTORY,
  ERROR_GET_CHAT_HISTORY,
  START_CREATE_CHAT_HISTORY,
  SUCCESS_CREATE_CHAT_HISTORY,
  ERROR_CREATE_CHAT_HISTORY,
  START_EDIT_CHAT_HISTORY,
  SUCCES_EDIT_CHAT_HISTORY,
  ERROR_EDIT_CHAT_HISTORY,
  START_DELETE_CHAT_HISTORY,
  SUCCESS_DELETE_CHAT_HISTORY,
  ERROR_DELETE_CHAT_HISTORY,
  START_VOTE_RESPONSE,
  SUCCES_VOTE_RESPONSE,
  ERROR_VOTE_RESPONSE,
  START_STORE_INSTAGRAM_PROFILE_IMAGE,
  SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE,
  ERROR_STORE_INSTAGRAM_PROFILE_IMAGE,
  START_GET_SINGLE_CHAT,
  SUCCESS_GET_SINGLE_CHAT,
  ERROR_GET_SINGLE_CHAT,
  START_POST_MSG_FEEDBACK,
  SUCCESS_POST_MSG_FEEDBACK,
  ERROR_POST_MSG_FEEDBACK,
  START_DELETE_CHAT_MESSAGE,
  SUCCESS_DELETE_CHAT_MESSAGE,
  ERROR_DELETE_CHAT_MESSAGE,
} from "../actions/chatAction";

import {
  sendMessage,
  getHistory,
  createHistory,
  deleteHistory,
  editChatTitle,
  voteResponse,
  getInstagramProfile,
  getSingleChat,
  postMsgFeedback,
  deleteChatMessage,
} from "../apis/chatApi";
import { START_GET_USER_DETAILS, UPDATE_USER_CREDITS } from "../actions/userAction";

function* sendMessageTaskAPI(data, signal , onSuccess = () => { }, onHistoryCreate = () => { }, onError = () => { }) {
  try {
    const response = yield call(sendMessage, data, signal);

    if (response.status) {
      yield put({
        type: SUCCESS_SEND_MESSAGE,
        data: response.data,
        chatId : data.chatId
      });

      yield put({
        type : UPDATE_USER_CREDITS,
        data : response.credits
      })

      if(response.trailEndedAndUpgraded){
        yield put({
          type : START_GET_USER_DETAILS,
        })
      }

      onSuccess(response.data);
    } else {

      yield put({
        type: ERROR_SEND_MESSAGE,
        data : response?.response,
        inputData : data,
        chatId : data.chatId,
      });
      onError(response?.response);
    }
  } catch (error) {
    yield put({
      type: ERROR_SEND_MESSAGE,
      chatId : data.chatId,
    });
    onError(error);
  }
}

function* sendMessageWorker({ data, onSuccess = () => { }, onHistoryCreate = () => { }, onError = () => { } ,onCancel = () => {} }) {
  const abortController = new AbortController();
  
  try {
    const sendMessageTask = yield fork(sendMessageTaskAPI, data, abortController.signal , onSuccess, onHistoryCreate , onError ); 

    const { cancelTask } = yield race({
      sendMessageCompleted: take(SUCCESS_SEND_MESSAGE),
      cancelTask: take(CANCEL_SEND_MESSAGE),
    });

    if (cancelTask) {
      yield cancel(sendMessageTask);
      abortController.abort();
      onCancel()
    }

  } catch (error) {

  } finally {
    abortController.abort();
  }
}

export function* sendMessageWatcher() {
  yield takeEvery(START_SEND_MESSAGE, sendMessageWorker);
}


function* getChatHistoryWorker({ params, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(getHistory , params);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_CHAT_HISTORY,
        data : response.data,
        params,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_GET_CHAT_HISTORY,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_CHAT_HISTORY,
    });
    onError(response)
  }
}

export function* getChatHistoryWatcher() {
  yield takeEvery(START_GET_CHAT_HISTORY, getChatHistoryWorker);
}

function* getSingleChatWorker({ chatId, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(getSingleChat , chatId);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_SINGLE_CHAT,
        data : response.data,
        chatId,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_GET_SINGLE_CHAT,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_SINGLE_CHAT,
    });
    onError(response)
  }
}

export function* getSingleChatWatcher() {
  yield takeEvery(START_GET_SINGLE_CHAT, getSingleChatWorker);
}

function* createHistoryWorker({data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(createHistory ,data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_CREATE_CHAT_HISTORY,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_CREATE_CHAT_HISTORY,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_CREATE_CHAT_HISTORY,
    });
    onError(response)
  }
}

export function* createHistoryWatcher() {
  yield takeEvery(START_CREATE_CHAT_HISTORY, createHistoryWorker);
}

function* editChatTitleWorker({chatId, data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(editChatTitle, chatId , data);
  try {
    if (response.status) {
      yield put({
        type: SUCCES_EDIT_CHAT_HISTORY,
        data : response.data,
        editData : data,
        chatId,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_EDIT_CHAT_HISTORY,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_EDIT_CHAT_HISTORY,
    });
    onError(response)
  }
}

export function* editChatTitleWatcher() {
  yield takeEvery(START_EDIT_CHAT_HISTORY, editChatTitleWorker)
}

function* postMsgFeedbackWorker({ msgId, chatId, data , onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(postMsgFeedback , msgId, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_POST_MSG_FEEDBACK,
        data : response.data,
        msgId,
        chatId,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_POST_MSG_FEEDBACK,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_POST_MSG_FEEDBACK,
    });
    onError(response)
  }
}

export function* postMsgFeedbackWatcher() {
  yield takeEvery(START_POST_MSG_FEEDBACK, postMsgFeedbackWorker);
}


function* deleteHistoryWorker({id,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(deleteHistory ,id);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_CHAT_HISTORY,
        id,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_DELETE_CHAT_HISTORY,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_DELETE_CHAT_HISTORY,
    });
    onError(response)
  }
}

export function* deleteHistoryWatcher() {
  yield takeEvery(START_DELETE_CHAT_HISTORY, deleteHistoryWorker)
}

function* deleteChatMessageWorker({chatId, id,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(deleteChatMessage ,id);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_CHAT_MESSAGE,
        id,
        chatId
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_DELETE_CHAT_MESSAGE,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_DELETE_CHAT_MESSAGE,
    });
    onError(response)
  }
}

export function* deleteChatMessageWatcher() {
  yield takeEvery(START_DELETE_CHAT_MESSAGE, deleteChatMessageWorker)
}

function* voteResponseWorker({id , chatId, data,onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(voteResponse, id , data);
  try {
    if (response.status) {
      yield put({
        type: SUCCES_VOTE_RESPONSE,
        data : response.data,
        editData : data,
        chatId
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_VOTE_RESPONSE,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_VOTE_RESPONSE,
    });
    onError(response)
  }
}

export function* voteResponseWatcher() {
  yield takeEvery(START_VOTE_RESPONSE, voteResponseWorker)
}


function* getInstagramProfileImageWorker({ url, onSuccess = () => { }, onError = () => { } }) {

  let response = yield select(state => state.chat.instagramProfileImages[url])
  if(!response){
    response = yield call(getInstagramProfile , url);
  }
  try {
    if (response) {
      yield put({
        type: SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE,
        imageUrl : url,
        data : response,
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_STORE_INSTAGRAM_PROFILE_IMAGE,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_STORE_INSTAGRAM_PROFILE_IMAGE,
    });
    onError(response)
  }
}

export function* getInstagramProfileImageWatcher() {
  yield takeEvery(START_STORE_INSTAGRAM_PROFILE_IMAGE, getInstagramProfileImageWorker);
}
