export const START_SEND_MESSAGE = "START_SEND_MESSAGE";
export const SUCCESS_SEND_MESSAGE = "SUCCESS_SEND_MESSAGE";
export const ERROR_SEND_MESSAGE = "ERROR_SEND_MESSAGE";
export const CANCEL_SEND_MESSAGE = "CANCEL_SEND_MESSAGE";

export const START_GET_CHAT_HISTORY = "START_GET_CHAT_HISTORY";
export const SUCCESS_GET_CHAT_HISTORY = "SUCCESS_GET_CHAT_HISTORY";
export const ERROR_GET_CHAT_HISTORY = "ERROR_GET_CHAT_HISTORY";

export const START_GET_SINGLE_CHAT = "START_GET_SINGLE_CHAT";
export const SUCCESS_GET_SINGLE_CHAT = "SUCCESS_GET_SINGLE_CHAT";
export const ERROR_GET_SINGLE_CHAT = "ERROR_GET_SINGLE_CHAT";

export const START_POST_MSG_FEEDBACK = "START_POST_MSG_FEEDBACK";
export const SUCCESS_POST_MSG_FEEDBACK = "SUCCESS_POST_MSG_FEEDBACK";
export const ERROR_POST_MSG_FEEDBACK = "ERROR_POST_MSG_FEEDBACK";

export const START_CREATE_CHAT_HISTORY = "START_CREATE_CHAT_HISTORY";
export const SUCCESS_CREATE_CHAT_HISTORY = "SUCCESS_CREATE_CHAT_HISTORY";
export const ERROR_CREATE_CHAT_HISTORY = "ERROR_CREATE_CHAT_HISTORY";

export const START_EDIT_CHAT_HISTORY = "START_EDIT_CHAT_HISTORY";
export const SUCCES_EDIT_CHAT_HISTORY = "SUCCES_EDIT_CHAT_HISTORY";
export const ERROR_EDIT_CHAT_HISTORY = "ERROR_EDIT_CHAT_HISTORY";

export const START_DELETE_CHAT_HISTORY = "START_DELETE_CHAT_HISTORY";
export const SUCCESS_DELETE_CHAT_HISTORY = "SUCCESS_DELETE_CHAT_HISTORY";
export const ERROR_DELETE_CHAT_HISTORY = "ERROR_DELETE_CHAT_HISTORY";

export const START_DELETE_CHAT_MESSAGE = "START_DELETE_CHAT_MESSAGE";
export const SUCCESS_DELETE_CHAT_MESSAGE = "SUCCESS_DELETE_CHAT_MESSAGE";
export const ERROR_DELETE_CHAT_MESSAGE = "ERROR_DELETE_CHAT_MESSAGE";

export const START_VOTE_RESPONSE = "START_VOTE_RESPONSE";
export const SUCCES_VOTE_RESPONSE = "SUCCES_VOTE_RESPONSE";
export const ERROR_VOTE_RESPONSE = "ERROR_VOTE_RESPONSE";

export const START_STORE_INSTAGRAM_PROFILE_IMAGE = "START_STORE_INSTAGRAM_PROFILE_IMAGE";
export const SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE = "SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE";
export const ERROR_STORE_INSTAGRAM_PROFILE_IMAGE = "ERROR_STORE_INSTAGRAM_PROFILE_IMAGE";

export const START_NEW_CHAT = "START_NEW_CHAT";