// import { Transition } from "../Components/Common/Dialog/DialogComponents";
import CircularProgress from "@mui/material/CircularProgress";

export const MuiButton = {
  defaultProps: {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
    component: (props) => {
      return <button {...props}>
        {props.loading && <CircularProgress style={{ marginRight: 4 }} />}
        {props.children}
      </button>
    }
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      backgroundColor: theme.color.primary.main,
      color: theme.color.white.main,
      textTransform: "none ",
      borderRadius: 5,
      fontSize: "1rem",
      padding: "0.5rem 1.25rem",
      lineHeight : 1.5,
      "& > span": {
        width: "1rem !important",
        height: "1rem !important",
        minWidth: "1rem !important",
        minHeight: "1rem !important",
        color: ownerState.variant === "secondary" ? theme.color.primary.main :
        ownerState.variant === "redVariant" ?
        theme.color.red.main
        :
        theme.color.white.main,
      },
      "&:hover": {
        background: `${theme.color.primary.main}80`,
      },
    }),
  },
  variants: [
    {
      props: { variant: "secondary" },
      style: ({ theme, ownerState }) => ({
        color: theme.color.primary.main,
        background: theme.color.white.main,
        border: `1px solid ${theme.color.primary.main}`,
        "&:hover": {
          background: `${theme.color.primary.main}20`,
        },
      }),
    },
    {
      props: { variant: "redVariant" },
      style: ({ theme, ownerState }) => ({
        color: theme.color.red.main,
        background: theme.color.white.main,
        border: `1px solid ${theme.color.red.main}`,
        "&:hover": {
          background: `${theme.color.red.main}20`,
        },
      }),
    },
    {
      props: { variant: "whiteOutlined" },
      style: ({ theme, ownerState }) => ({
        color: theme.color.white.main,
        background: "transparent",
        border: `1px solid ${theme.color.white.main}`,
        "&:hover": {
          background: `${theme.color.white.main}20`,
        },
      }),
    },
    {
      props: { fullWidth: true },
      style: {
        width: "100%",
      },
    },
    {
      props: { variant: "textOnly" },
      style: ({ theme, ownerState }) => ({
        color: theme.color.primary.main,
        background: theme.color.white.main,
        border: `1px solid ${theme.color.white.main}`,
        "&:hover": {
          background: `${theme.color.primary.main}20`,
        },
      }),
    },
  ],
};


export const MuiTypography = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      lineHeight: 1.5,
      color: theme.color?.[ownerState?.color || "black"]?.main,
    }),
  },
  variants: [
    {
      props: { variant: "h1" },
      style: ({ theme, ownerState }) => {
        return{
        fontSize: '5rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }},
    },
    {
      props: { variant: "h2" },
      style: ({ theme, ownerState }) => ({
        fontSize: '3.75rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "h3" },
      style: ({ theme, ownerState }) => ({
        fontSize: '3rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "h4" },
      style: ({ theme, ownerState }) => ({
        fontSize: '2rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "h5.5" },
      style: ({ theme, ownerState }) => ({
        fontSize: '1.875rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "h5" },
      style: ({ theme, ownerState }) => ({
        fontSize: '1.5rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "h6" },
      style: ({ theme, ownerState }) => ({
        fontSize: '1.25rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,

      }),
    },
    {
      props: { variant: "subtitle" },
      style: ({ theme, ownerState }) => ({
        fontSize: '1rem',
        color: theme.color?.[ownerState?.color || "black"]?.main,
      }),
    },
    {
      props: { variant: "body" },
      style: ({ theme, ownerState }) => ({
        fontSize: '0.875rem',
        color: theme.color?.[ownerState?.color || "white"]?.main,
      }),
    },
    {
      props: { variant: "overline" },
      style: ({ theme, ownerState }) => ({
        fontSize: '0.75rem',
        color: theme.color?.[ownerState?.color || "white"]?.main,
      }),
    },
    {
      props: { upperCase: true },
      style: {
        textTransform: "uppercase",
      },
    },
    {
      props: { capitalize: true },
      style: {
        textTransform: "capitalize",
      },
    },
    {
      props: { bold: true },
      style: {
        fontWeight: "700",
      },
    },
    {
      props: { semiBold: true },
      style: {
        fontWeight: "600",
      },
    },
    {
      props: { medium: true },
      style: {
        fontWeight: "500",
      },
    },
    {
      props: { regular: true },
      style: {
        fontWeight: "400",
      },
    },
    {
      props: { light: true },
      style: {
        fontWeight: "300",
      },
    },
    {
      props: { alignCenter: true },
      style: {
        textAlign: "center",
      },
    },
    {
      props: { alignRight: true },
      style: {
        textAlign: "right",
      },
    },
    {
      props: { pointer: true },
      style: {
        cursor: "pointer",
      },
    },
    {
      props: { underline: true },
      style: {
        textDecoration: "underline",
      },
    },

  ],
};


export const MuiAutocomplete = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color: theme.color.black.main,
      textTransform: "none ",
      borderRadius: 5,
      "& > div": {
        "& > div": {
          padding: "0.625rem 1.25rem !important",
          "& input" : {
            fontSize: "16px",
            padding : "0px !important"
          }
        },
        "& > fieldset": {
          borderColor: theme.color.secondary.main,
        },
        "&:hover": {
          "& > fieldset": {
            borderColor: `${theme.color.secondary.hover} !important`,
          }
        }
      }
    }),
  },
}

export const MuiSelect = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      backgroundColor: theme.color.white.main,
      color: theme.color.black.main,
      textTransform: "none ",
      borderRadius: 5,
      borderColor: theme.color.secondary.main,
      "& > fieldset": {
        borderColor: theme.color.secondary.main,
      },
      "&:hover": {
        "& > fieldset": {
          borderColor: `${theme.color.secondary.hover} !important`,
        }
      },
      "& > div": {
        minHeight : "1.6em !important",
        "& > input": {
          padding: "0.625rem 1.25rem",
          fontSize: "16px",
        },
      }
    }),
  },
}



export const MuiTextField = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      backgroundColor: theme.color.white.main,
      color: theme.color.black.main,
      textTransform: "none ",
      borderRadius: 5,
      "& > div": {
        "& > input": {
          padding: "0.625rem 1.25rem",
          fontSize: "16px",
        },
        "& > fieldset": {
          borderColor: theme.color.secondary.main,
        },
        "&:hover": {
          "& > fieldset": {
            borderColor: `${theme.color.secondary.hover} !important`,
          }
        }
      }
    }),
  },
  variants: [
    {
      props: { multiline: true },
      style: ({ theme, ownerState }) => ({
        "& > div": {
          padding: "10px 20px",
          alignItems: "end",
          "& > textarea": {
            fontSize: "16px",
          },
          "& > fieldset": {
            borderColor: theme.color.secondary.main,
          },
          "&:hover": {
            "& > fieldset": {
              borderColor: `${theme.color.secondary.hover} !important`,
            }
          }
        }
      }),
    },
    {
      props: { minimal: true },
      style: ({ theme, ownerState }) => ({
        background : "transparent",
        color : theme.color.primary.main,
        "& > div": {
          padding: "0px 0px 0px 4px",
          alignItems: "end",
          color : "inherit",
          "& > input": {
            fontSize: 16,
            padding: "0px 0px 0px 4px",
            color : "inherit",
          },
          "& > fieldset": {
            borderColor:  theme.color.primary.main,
            color : "inherit",
          }
        }
      }),
    },
  ],
}


export const MuiDivider = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color: theme.color.black.main,
    }),
  },
  variants: [

  ],
}

export const MuiFormHelperText = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      color: theme.color.red.main,
    }),
  },
  variants: [
  ],
}


export const MuiAvatar = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({

    }),
  },
  variants: [
    {
      props: { small: true },
      style: ({ theme, ownerState }) => ({
        width: 24,
        height: 24,
        borderRadius: 5,
        fontSize : "0.9rem"
      }),
    },
  ],
}


export const MuiMenu = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({

    }),
  },
  variants: [
    {
      props: { variant: "blueVariant" },
      style: ({ theme, ownerState }) => ({
        "& .MuiPaper-root": {
          background: theme.color.background.paper,
          color: theme.color.primary.main,
          boxShadow: "none",
        }
      }),
    },
  ],
}


export const MuiSkeleton = {
  defaultProps: {
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({

    }),
  },
  variants: [
    {
      props: { white: true },
      style: ({ theme, ownerState }) => ({
        backgroundColor : "rgba(255,255,255,0.4)"
      }),
    },
  ],
}

export const MuiTooltip = {
  defaultProps: {
    arrow : true,
    placement: "top"
  },
  styleOverrides: {
    arrow: ({ theme, ownerState }) => ({
      color : theme.color.white.main,
    }),
    tooltip: ({ theme, ownerState }) => ({
      background : theme.color.white.main,
      filter : "drop-shadow(2px 4px 6px rgba(0,0,0,0.16))",
      color : theme.color.black.main,
      fontSize : "0.9rem",
      fontWeight : 400,
      padding : "0.75rem",
      borderRadius : "0.75rem",
      maxWidth : "40vw",
    })
  },
  variants: [
    // {
    //   props: { white: true },
    //   style: ({ theme, ownerState }) => ({
    //     backgroundColor : "rgba(255,255,255,0.4)"
    //   }),
    // },
  ],
}


export const MuiBadge = {
  styleOverrides: {
    badge: ({ theme, ownerState }) => ({
      background : theme.color.primary.main,
      width : "0.75rem",
      height : "0.75rem",
      borderRadius : "50px"
    }),
  }
}
