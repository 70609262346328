import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { START_GET_USER_DETAILS } from "../actions/userAction";
import { resendVerificationLink } from "../apis/userApi";

const useStyles = makeStyles((theme) => ({
  AuthPageMain: {
    marginTop: "9vh",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    [theme.breakpoints.down(theme.breakpoints.ssm)]: {
      marginTop: 32,
      marginBottom: 16,
    },
  },
  PageLogo: {
    width: "35vw",
    margin: "0px auto 40px",
    display: "flex",
    [theme.breakpoints.down(theme.breakpoints.md)]: {
      width: "40vw",
      marginTop: "2rem",
    },
    [theme.breakpoints.down(theme.breakpoints.sm)]: {
      width: "60vw",
      marginTop: "2rem",
    },
  },
  PendingVerification: {
    display: "flex",
    flexDirection: "column",
    gap: "3rem",
    justifyContent: "center",
    "& > div": {
      textAlign: "center",
    },
    "& > div:first-child" : {
      [theme.breakpoints.down(theme.breakpoints.sm)]: {
        padding : "1rem",
        "& > p" : {
          width : "90%",
          margin : "0px auto"
        }
      },
    },
  },
  PendingVerificationUser: {
    background : "#FFFFFF",
    padding : "1rem",
    borderRadius : "0.5rem",
    boxShadow: `0px 1px 16px 0px #0000001A`,
    "& > div:first-child": {
      display: "flex",
      alignItems: "center",
      gap: "0.25rem",
    },
    "& > div:nth-child(2)" : {
      justifyContent : "end",
      display : "flex",
    },
  },
  PendingVerificationResend: {
    marginBottom : "2rem",
    padding : "1rem",
    borderRadius : "0.5rem",
    boxShadow: `0px 1px 16px 0px #0000001A`,
    display: "flex",
    alignItems : "center",
    "& > div:nth-child(2)" : {
      justifyContent : "end",
      display : "flex",
    },
    "& > div > p": {
      textAlign: "start !important",
    },
  },
}));

const PendingVerification = () => {
  const classes = useStyles();
  const path = window.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resendLoading, setResendLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);


  useEffect(() => {
    let pollingInterval;

    pollingInterval = setInterval(() => {
      dispatch({
        type: START_GET_USER_DETAILS,
        onSuccess: (res) => {
          if (res.data.email_verified == true) {
            navigate("/chat");
          } else {
            navigate("/pending-verification");
          }
        },
      });
    }, 3000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, []);

  const HandleResend = async () => {
    setResendLoading(true);
    setSent(false);
    await resendVerificationLink().then((res) => {
      if (res.status) {
        setSent(true);
      }
    });
    setResendLoading(false);
  };

  return (
    <div className={classes.AuthPageMain}>
      <img alt="" className={classes.PageLogo} src={"../assets/img/emailpending.svg"} />
      <Grid container className={classes.PendingVerification}>
        <Grid item sm={12}>
          <Typography variant="h4" semibold>
            Pending Verification
          </Typography>
          <Typography>
            Please verify your email by visiting link sent to your email.
          </Typography>
        </Grid>

        <Grid item md={6} sm={6} xs={11}>
          <Grid container className={classes.PendingVerificationResend}>
            <Grid item xs={8}>
              <Typography>Resend verification link</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={sent}
                loading={resendLoading}
                onClick={() => {
                  HandleResend();
                }}
              >
                {sent ? "Sent" : "Resend"}
              </Button>
            </Grid>
          </Grid>

            <Grid container className={classes.PendingVerificationUser}>
              <Grid item xs={8}>
                <Avatar
                  small="true"
                  alt={`${userDetails?.firstname ?? ""}  ${
                    userDetails?.lastname ?? ""
                  }`}
                  src={userDetails?.profileImage}
                />
                <span>{`${userDetails?.firstname ?? ""}  ${
                  userDetails?.lastname ?? ""
                }`}</span>
              </Grid>
              <Grid item xs={4}>
                <Button
                variant="secondary"
                  onClick={() => {
                    window.localStorage.clear();
                    window.location.reload();
                  }}
                >
                  Logout
                </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PendingVerification;
