import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress, Typography } from "@mui/material";
import { verifyEmail } from "../apis/userApi";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  AuthPageMain: {
      marginTop: "9vh",
      display: "flex",
      flexDirection: "column",
      height: "fit-content",
      [theme.breakpoints.down(theme.breakpoints.ssm)]: {
          marginTop: 32,
          marginBottom: 16,
      },
  },
  PageLogo: {
      width: 91,
      margin: "0px auto 40px",
      display: "flex",
      [theme.breakpoints.down(theme.breakpoints.ssm)]: {
          width: 56,
          marginBottom: 14,
      },
  },
  InvalidToken : {
    marginTop : "5rem",
    display : "flex",
    justifyContent : "center",
    alignItems : "center",
    gap : "0.5rem",
    flexDirection : "column",
  },
  Verifying : {
    marginTop : "5rem",
    display : "flex",
    justifyContent : "center",
    alignItems : "center",
    gap : "2rem",
    flexDirection : "column",
    "& > span" : {
      color : theme.color.primary.main,
      width : "5rem !important",
      height : "5rem !important"
    }
  },
}))


const VerifyEmail = () => {
  const classes = useStyles()
  const navigate = useNavigate();
  const path = window.location.pathname.split("/")[1]
  
  const [invalidToken, setInvalidToken] = useState(null);

  useEffect(() => {
    if(path == "verify-email"){
      let resetToken = window.location.search.split("?token=")[1]
      if(resetToken){
        verifyEmail({token : resetToken})
        .then(res => {
            if(res.status){
              window.localStorage.setItem("token", res.accessToken)
              window.location.href = `${window.location.origin}`
            } else {
                setInvalidToken({message : res.error})
            }
        })
      } else {
        setInvalidToken({message : "Invalid token"})
      }
  }
  },[])

  return (<div className={classes.AuthPageMain}>
      <img alt="" className={classes.PageLogo} src={"../assets/img/logo.png"} />
      
      {invalidToken  ? 
      <div  className={classes.InvalidToken}>
        <Typography variant="h5" semiBold>Invalid token</Typography>
        <Typography>Please login and resend varification link</Typography>
        <Button onClick={() => navigate("/login")}>Login</Button>
      </div>
      :  
      <div  className={classes.Verifying}>
        <CircularProgress />
        <Typography variant="h5" semiBold>Verifying...</Typography>      
      </div>
    }
  </div>
  );
};

export default VerifyEmail;