import {
  START_SEND_MESSAGE,
  SUCCESS_SEND_MESSAGE,
  ERROR_SEND_MESSAGE,
  START_GET_CHAT_HISTORY,
  SUCCESS_GET_CHAT_HISTORY,
  ERROR_GET_CHAT_HISTORY,
  START_CREATE_CHAT_HISTORY,
  SUCCESS_CREATE_CHAT_HISTORY,
  ERROR_CREATE_CHAT_HISTORY,
  START_EDIT_CHAT_HISTORY,
  SUCCES_EDIT_CHAT_HISTORY,
  ERROR_EDIT_CHAT_HISTORY,
  START_DELETE_CHAT_HISTORY,
  SUCCESS_DELETE_CHAT_HISTORY,
  ERROR_DELETE_CHAT_HISTORY,
  START_VOTE_RESPONSE,
  SUCCES_VOTE_RESPONSE,
  ERROR_VOTE_RESPONSE,
  START_STORE_INSTAGRAM_PROFILE_IMAGE,
  SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE,
  ERROR_STORE_INSTAGRAM_PROFILE_IMAGE,
  START_GET_SINGLE_CHAT,
  SUCCESS_GET_SINGLE_CHAT,
  ERROR_GET_SINGLE_CHAT,
  START_POST_MSG_FEEDBACK,
  SUCCESS_POST_MSG_FEEDBACK,
  ERROR_POST_MSG_FEEDBACK,
  START_NEW_CHAT,
  START_DELETE_CHAT_MESSAGE,
  SUCCESS_DELETE_CHAT_MESSAGE,
  ERROR_DELETE_CHAT_MESSAGE,
} from "../actions/chatAction";

const initialState = {
  chatHistoryLoading: false,
  chatHistory: [],
  currentPage: 1,
  totalPages: null,
  instagramProfileImages: {},
  currentConversation: null,
  currentLoading: null,
  feedBackLoading : null,
};

const chatReducer = (prevState, action) => {
  switch (action.type) {
    case START_NEW_CHAT: 
      return {
        ...prevState,
        currentConversation : null
      }
    case START_GET_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistoryLoading: true,
        chatHistory: action.params.page == 1 ? [] : [...prevState.chatHistory],
      };
    case SUCCESS_GET_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistoryLoading: false,
        chatHistory:
          action.params.page == 1
            ? action.data.data
            : [...prevState.chatHistory, ...action.data.data],
        currentPage: action.data.currentPage,
        totalPages: action.data.totalPages,
      };
    case ERROR_GET_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistoryLoading: false,
      };
    case START_GET_SINGLE_CHAT:
      return {
        ...prevState,
        currentLoading: true,
      };
    case SUCCESS_GET_SINGLE_CHAT:
      let currentConversationTemp = {};
      if (action?.data) {
        currentConversationTemp = {
          ...action?.data,
          messages: action?.data?.messages?.map((m) => {
            if (m?.responseId?.response) {
              return {
                ...m,
                status: "results",
              };
            } else return m;
          }),
        };
      }
      return {
        ...prevState,
        currentLoading: false,
        currentConversation: currentConversationTemp,
      };
    case ERROR_GET_SINGLE_CHAT:
      return {
        ...prevState,
        currentLoading: false,
      };
    case START_SEND_MESSAGE:
      return {
        ...prevState,
      };
    case SUCCESS_SEND_MESSAGE:
      try {
        if (action.chatId) {
          return {
            ...prevState,
            chatHistory: prevState.chatHistory.map((chat) => {
              if (chat?._id === action.chatId) {
                return action.data;
              } else return chat;
            }),
          };
        } else {
          return {
            ...prevState,
            chatHistory: [action.data, ...prevState.chatHistory],
          };
        }
      } catch (err) {
        console.log(">>>>>>>>>>>>>>>>>>>>>err", err);
      }

    case ERROR_SEND_MESSAGE:
      if(action.data && action.chatId && (prevState?.currentConversation?._id === action.chatId)){
          return {
            ...prevState,
            currentConversation: {
              ...prevState?.currentConversation,
              messages :  [...prevState?.currentConversation?.messages , {
              title : action?.inputData?.query,
              status : action?.data?.data || "error"
            }]}
          };
      } else if(action.data){
        return {
          ...prevState,
          currentConversation: {
            title : action?.inputData?.query,
            messages :  [{
            title : action?.inputData?.query,
            status : action?.data?.data || "error"
          }]}
        };
      } else {
        return {
          ...prevState,
        };
      }

    case START_CREATE_CHAT_HISTORY:
      return {
        ...prevState,
      };
    case SUCCESS_CREATE_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistory: [action.data, ...prevState.chatHistory],
      };
    case ERROR_CREATE_CHAT_HISTORY:
      return {
        ...prevState,
      };
    case START_EDIT_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistory: prevState.chatHistory.map((historyItem) => {
          if (historyItem?._id == action.chatId) {
            return {
              ...historyItem,
              ...action.data
            }
          } else return historyItem;
        }),
        currentConversation : prevState?.currentConversation?._id === action.chatId ? {
          ...prevState?.currentConversation,
          ...action.data
        } : prevState?.currentConversation
      };
    case SUCCES_EDIT_CHAT_HISTORY:
    return {
        ...prevState,
        chatHistory: prevState.chatHistory.map((historyItem) => {
          if (historyItem?._id == action.chatId) {
            return action.data
          } else return historyItem;
        }),
        currentConversation : prevState?.currentConversation?._id === action.chatId ? {
          ...prevState?.currentConversation,
          ...action.editData
        } : prevState?.currentConversation
      };
    case ERROR_EDIT_CHAT_HISTORY:
      return {
        ...prevState,
      };
      case START_POST_MSG_FEEDBACK:
        return {
          ...prevState,
          feedBackLoading : action.msgId
        };
      case SUCCESS_POST_MSG_FEEDBACK:
        return {
          ...prevState,
          feedBackLoading : null,
          chatHistory: prevState.chatHistory.map((historyItem) => {
            if (historyItem?._id == action.chatId) {
              return {
                ...historyItem,
                messages: historyItem.messages.map((msg) => {
                  if (msg?._id === action.data?._id) {
                    return action.data;
                  } else return msg;
                }),
              };
            } else return historyItem;
          }),
        };
      case ERROR_POST_MSG_FEEDBACK:
        return {
          ...prevState,
          feedBackLoading : null,
        };
    case START_DELETE_CHAT_HISTORY:
      return {
        ...prevState,
      };
    case SUCCESS_DELETE_CHAT_HISTORY:
      return {
        ...prevState,
        chatHistory: prevState.chatHistory.filter(
          (historyItem) => historyItem?._id !== action.id
        ),
        currentConversation : prevState?.currentConversation?._id === action.id ? null : prevState?.currentConversation
      };
    case ERROR_DELETE_CHAT_HISTORY:
      return {
        ...prevState,
      };
    case START_VOTE_RESPONSE:
      return {
        ...prevState,
        chatHistory: prevState.chatHistory.map((historyItem) => {
          if (historyItem?._id == action.chatId) {
            return {
              ...historyItem,
              ...action.data
            }
          } else return historyItem;
        }),
        currentConversation : prevState?.currentConversation?._id === action.chatId ? {
          ...prevState?.currentConversation,
          ...action.data
        } : prevState?.currentConversation
      };
    case SUCCES_VOTE_RESPONSE:
      return {
        ...prevState,
        chatHistory: prevState.chatHistory.map((historyItem) => {
          if (historyItem?._id == action.chatId) {
            return action.data
          } else return historyItem;
        }),
        currentConversation : prevState?.currentConversation?._id === action.chatId ? {
          ...prevState?.currentConversation,
          ...action.editData
        } : prevState?.currentConversation
      };
    case ERROR_VOTE_RESPONSE:
      return {
        ...prevState,
      };
    case SUCCESS_STORE_INSTAGRAM_PROFILE_IMAGE:
      let updatedInstagramProfileImages = {
        ...prevState.instagramProfileImages,
      };
      updatedInstagramProfileImages[action.imageUrl] = action.data;
      return {
        ...prevState,
        instagramProfileImages: updatedInstagramProfileImages,
      };
    case START_DELETE_CHAT_MESSAGE:
      return {
        ...prevState,
      };
    case SUCCESS_DELETE_CHAT_MESSAGE:
      let updatedConversation = prevState?.currentConversation?._id === action.chatId ? {
        ...prevState?.currentConversation,
        messages : prevState?.currentConversation?.messages?.filter(a => a._id !== action?.id)
      } : prevState?.currentConversation

      if(updatedConversation?.messages?.length === 0){
        updatedConversation = null
      }

    return {
        ...prevState,
        currentConversation : updatedConversation
      };
    case ERROR_DELETE_CHAT_MESSAGE:
      return {
        ...prevState,
      };
    default:
      return prevState || initialState;
  }
};

export default chatReducer;
