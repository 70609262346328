import './App.css';
import { ThemeProvider } from '@mui/material';
import theme from './theme/theme';
import RoutesComponent from './routes/RoutesComponent';
import { Provider } from 'react-redux';
import store from "./store"
import { BrowserRouter as Router } from 'react-router-dom';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <RoutesComponent />
        </Router >
      </Provider>
    </ThemeProvider>
  );
}

export default App;
