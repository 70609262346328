import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import FallbackLoader from "../components/common/FallbackLoader";
import PrivateRoutes from "./PrivateRoutes";
import AuthRoutes from "./AuthRoutes";
import VerifyEmail from "../pages/VerifyEmail";
import PendingVerification from "../pages/PendingVerification";
const UpdatePlans = lazy(() => import("../pages/UpdatePlans"));
const TOS = lazy(() => import("../pages/TOS"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const Layout = lazy(() => import("./Layout"));
const ChatLayoutContainer = lazy(() => import("./ChatLayoutContainer"));
const AuthLayout = lazy(() => import("./AuthLayout"));
const AuthrizationPage = lazy(() => import("../pages/AuthrizationPage"));
const SSOAuth = lazy(() => import("../pages/SSOAuth"));
const Home = lazy(() => import("../pages/Home"));
const Chat = lazy(() => import("../pages/Chat"));
const MyAccount = lazy(() => import("../pages/MyAccount"));
const SavedResults = lazy(() => import("../pages/SavedResults"));
const Trackers = lazy(() => import("../pages/Trackers"));
const Plans = lazy(() => import("../pages/Plans"));
const Contact = lazy(() => import("../pages/Contact"));
const AdminMessages = lazy(() => import("../pages/AdminMessages"));
const AdminUsers = lazy(() => import("../pages/AdminUsers"));
const AdminDashboard = lazy(() => import("../pages/AdminDashboard"));
const AdminSubscriptions = lazy(() => import("../pages/AdminSubscriptions"));
// const AdminImport = lazy(() => import("../pages/AdminImport"));

const RoutesComponent = () => {
  return (
    <Suspense fallback={<FallbackLoader />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {/* <Route exact path="/" element={<Navigate to='/chat' />} /> */}
          <Route
            exact
            path="/chat/:chatId"
            element={<ChatLayoutContainer component={<Chat />} />}
          />
          <Route
            exact
            path="/chat"
            element={<ChatLayoutContainer component={<Chat />} />}
          />
          <Route
            exact
            path="/account"
            element={<Layout accountSidebar component={<MyAccount />} />}
          />
          <Route
            exact
            path="/saved-results"
            element={<Layout accountSidebar component={<SavedResults />} />}
          />
          <Route
            exact
            path="/trackers"
            element={<Layout accountSidebar component={<Trackers />} />}
          />
          <Route
            exact
            path="/contact"
            element={<Layout accountSidebar component={<Contact />} />}
          />
          <Route
            exact
            path="/plans"
            element={<Layout accountSidebar component={<Plans />} />}
          />
          <Route
            exact
            path="/admin"
            element={<Layout adminSidebar component={<AdminDashboard />} />}
          />
          <Route
            exact
            path="/admin-messages"
            element={<Layout adminSidebar component={<AdminMessages />} />}
          />
          <Route
            exact
            path="/admin-user"
            element={<Layout adminSidebar component={<AdminUsers />} />}
          />
          <Route
            exact
            path="/admin-subscription"
            element={<Layout adminSidebar component={<AdminSubscriptions />} />}
          />
          {/* <Route
            exact
            path="/admin-import"
            element={<Layout adminSidebar component={<AdminImport />} />}
          /> */}
          <Route
            exact
            path="/manage-subscription"
            element={<Layout accountSidebar component={<UpdatePlans />} />}
          />
          <Route
            path="/pending-verification"
            element={<AuthLayout component={<PendingVerification />} />}
          />
        </Route>
        <Route element={<AuthRoutes />}>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/login"
            element={<AuthLayout component={<AuthrizationPage />} />}
          />
          <Route
            path="/register"
            element={<AuthLayout component={<AuthrizationPage />} />}
          />
          <Route
            path="/auth"
            element={<AuthLayout component={<SSOAuth />} />}
          />
        </Route>

        <Route
          path="/forgot-password"
          element={<AuthLayout component={<AuthrizationPage />} />}
        />
        <Route
          path="/forgot-password-success"
          element={<AuthLayout component={<AuthrizationPage />} />}
        />
        <Route
          path="/reset-password"
          element={<AuthLayout component={<AuthrizationPage />} />}
        />
        <Route
          path="/reset-password-success"
          element={<AuthLayout component={<AuthrizationPage />} />}
        />
        <Route
          path="/verify-email"
          element={<AuthLayout component={<VerifyEmail />} />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TOS />} />

        <Route path="/*" element={<h1>Page not found</h1>} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
