import API from "./api";

export function getHistory(params) {
    return API.get(`chat`,params);
}

export function createHistory(data) {
    return API.post(`chat/create`, data);
}

export function getSingleChat(id) {
    return API.get(`chat/${id}`);
}

export function editChatTitle(id , data) {
    return API.put(`chat/${id}` , data);
}

export function deleteHistory (id) {
    return API.deleteM(`chat/${id}`);
}

export function voteResponse(id , data) {
    return API.put(`chat/vote/${id}` , data);
}

export function sendMessage(data , signal) {
    return API.post(`chat/message`, data , signal);
}

export function getInstagramProfile(url) {
    return  fetch(`${process.env.REACT_APP_BASE_API_URL}proxy-image?url=${encodeURIComponent(url)}`)
    .then((response) => response.blob())
    .then((data) => URL.createObjectURL(data))
    .catch((error) => {
        console.error('Error fetching image:', error);
    })
}

export function sendMessageAdmin(data , signal) {
    return API.post(`chat/message/admin`, data , signal);
}

export function markResponseNotUseful(data) {
    return API.post(`chat/admin/notuseful/${data.id}`, data.data);
}

export function postMsgFeedback(id , data) {
    return API.post(`chat/feedback/${id}` , data);
}

export function deleteChatMessage (id) {
    return API.deleteM(`chat/message/${id}`);
}