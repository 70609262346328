import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
  FallbackLoaderMain: {
    width: "100%",
    height: "100%",
    position: "absolute",
    "& > div": {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > span": {
        width: "20%",
        height: "20%"
      }
    }
  }
}));

const FallbackLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.FallbackLoaderMain} >
      <div >
        <CircularProgress />
      </div>
    </div>
  )
}
export default FallbackLoader;




