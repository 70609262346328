import { all } from "redux-saga/effects";
import {
    loginUserWatcher,
    registerUserWatcher,
    userDetailsWatcher,
    editUserDetailsWatcher,
    createPaymentLinkWatcher,
    forgotPasswordWatcher,
} from "./userSagas";

import {
    sendMessageWatcher,
    getChatHistoryWatcher,
    createHistoryWatcher,
    editChatTitleWatcher,
    deleteHistoryWatcher,
    voteResponseWatcher,
    getInstagramProfileImageWatcher,
    getSingleChatWatcher,
    postMsgFeedbackWatcher,
    deleteChatMessageWatcher,
} from "./chatSagas"
import { adminUpdateUserWatcher, adminUserListWatcher } from "./adminSagas";
import { createTrackerWatcher, deleteTrackerWatcher, getTrackersListWatcher } from "./trackerSagas";



export default function* rootSaga() {
    yield all([
        loginUserWatcher(),
        registerUserWatcher(),
        userDetailsWatcher(),
        editUserDetailsWatcher(),
        sendMessageWatcher(),
        forgotPasswordWatcher(),
        getChatHistoryWatcher(),
        createHistoryWatcher(),
        editChatTitleWatcher(),
        deleteHistoryWatcher(),
        createPaymentLinkWatcher(),
        voteResponseWatcher(),
        getInstagramProfileImageWatcher(),
        adminUserListWatcher(),
        adminUpdateUserWatcher(),
        getSingleChatWatcher(),
        postMsgFeedbackWatcher(),
        getTrackersListWatcher(),
        createTrackerWatcher(),
        deleteTrackerWatcher(),
        deleteChatMessageWatcher()
    ]);
}
