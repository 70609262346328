import {
  call,
  put,
  takeEvery,
} from "@redux-saga/core/effects";

import {
  START_GET_TRACKERS,
  SUCCESS_GET_TRACKERS,
  ERROR_GET_TRACKERS,
  START_CREATE_TRACKER,
  SUCCES_CREATE_TRACKER,
  ERROR_CREATE_TRACKER,
  START_DELETE_TRACKER,
  SUCCES_DELETE_TRACKER,
  ERROR_DELETE_TRACKER,
} from "../actions/trackerAction";
import { getTrackersList, createTracker, deleteTracker } from "../apis/trackerApi";

function* getTrackersListWorker({onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(getTrackersList);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_TRACKERS,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_GET_TRACKERS,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_TRACKERS,
    });
    onError(response)
  }
}

export function* getTrackersListWatcher() {
  yield takeEvery(START_GET_TRACKERS, getTrackersListWorker);
}

function* createTrackerWorker({data, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(createTracker , data);
  try {
    if (response.status) {
      yield put({
        type: SUCCES_CREATE_TRACKER,
        data : response.data
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_CREATE_TRACKER,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_CREATE_TRACKER,
    });
    onError(response)
  }
}

export function* createTrackerWatcher() {
  yield takeEvery(START_CREATE_TRACKER, createTrackerWorker);
}

function* deleteTrackerWorker({id, onSuccess = () => { }, onError = () => { } }) {
  let response = yield call(deleteTracker , id);
  try {
    if (response.status) {
      yield put({
        type: SUCCES_DELETE_TRACKER,
        id
      });
      onSuccess(response)
    } else {
      yield put({
        type: ERROR_DELETE_TRACKER,
      });
      onError(response)
    }
  } catch (error) {
    yield put({
      type: ERROR_DELETE_TRACKER,
    });
    onError(response)
  }
}

export function* deleteTrackerWatcher() {
  yield takeEvery(START_DELETE_TRACKER, deleteTrackerWorker);
}
