import { combineReducers } from 'redux';
import chatReducer from './chatReducer';
import userReducer from './userReducer';
import adminReducer from './adminReducer';
import trackerReducer from './trackerReducer';

const rootReducer = combineReducers({
    chat: chatReducer,
    user: userReducer,
    admin : adminReducer,
    tracker : trackerReducer
});

export default rootReducer;
